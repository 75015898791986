import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//fontawsome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

//components
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import StayLoop from "../../components/Home/stayloop";
import MoreArticles from "../../components/Home/morearticles";

const BlogSingle = (props) => {
  const { blog: post } = props.data
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: morepost } = props.data.moreArticles
  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "Digital Transformation for Retail Supply Chain"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />


      {/* blog description section */}

      <section className="mt-60 lg:mt-20 pt-6-6 pb-35 lg:pt-51 lg:pb-59">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="hidden xl:block w-full lg:w-1/6"></div>
            <div className="w-full lg:w-4/5 xl:w-2/3">
              <h2 className="mb-3-3 lg:mb-6-3 lg:mb-1-3 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-term-primary">{post.frontmatter.title}</h2>
              <div className="blog-inner--cnt text-p4 lg:text-p2 font-worksans-normal text-term-primary"><p>No one saw the pandemic coming. Retail businesses could not forecast what it could mean to their supply chain operations. So many supply chains experienced pressure, which exposed loopholes and serious shortcomings. Most retailers thought they had plenty of time to fix challenges while shifting to eCommerce. As per McKinsey research, the pandemic forced 10 years of growth in only 3 short months.</p>
                <p>As we try to gain our footing through the pandemic and the way consumers shop changes, it’s clear supply chain operations can be a big differentiator for retail businesses. It really helps to create value for businesses and their customers. However, you have to enable your supply chain digitally to meet today’s customer expectations.</p>
                <p>For example, consumers compare lots of different shipping options before making a purchase. They look for the best available deal. According to Salesforce, nearly 60% consumers are willing to switch to a competitor who has quicker and cheaper shipping.</p>
                <p>On an ecommerce site, the sale doesn’t end at checkout. Retailers need to deliver the product to the consumer’s doorstep as promised. A negative shipping experience such as delays or poor package handling can severely harm your reputation. According to Shipstation, 83% of consumers are less likely to purchase from a retailer again after a bad shipping experience.</p>
                <p>Unsatisfied consumers will leave a negative review or post on their social media accounts about their ill experience. Your brand can go under quickly.</p>
                <p>Most retailers struggle meeting consumer’s expectations, infact 95% of companies have experienced delays in their order fulfillment process. Nearly half faced issues with order picking and handling, while more than a third struggled with effective inventory management.</p>
                <h2>Digital Transformation Benefits for Retail</h2>
                <p>Warehouse operators are preparing to meet order fulfillment and delivery challenges head-on. They are investing in new technologies and digitizing their processes so their supply chains can meet ecommerce demand hikes. Just like any digital transformation process, you must adopt a new mindset rather than fancy systems. You don’t just need technology that works or upgraded order fulfillment technology. You also have to improve inventory management and delivery management capabilities.</p>
                <p>When done right, here are the main benefits of a digitally transformed supply chain:</p>
                <h2>Speedy Delivery</h2>
                <p>Consumers do not prefer to wait longer to receive a product. They look for options that deliver products faster at a cheaper rate. Consumer’s expectations are increasingly rising on shipping times. Previously, 4-5 days or even 10-15 days was acceptable. Now, it is the same day, next day or a two-day free shipping. These high expectations are creating one of the biggest impacts in the ecommerce industry.</p>
                <p>According to My Total Retail, when a company does not offer same-day delivery, one-third of consumers feel frustrated. 74% consumers are more likely to re-purchase if they receive same-day delivery. To make this kind of delivery happen, retailers need digitally automated workflows and strategic inventory management. A tight, well-synchronized integration between
                  your eCommerce platform and order management systems can sync new orders in time to pick and pack for next-day delivery.</p>
                <h2>Omnichannel Fulfilment</h2>
                <p>To make the deliveries quick, retailers typically offer consumers different shipping options like Buy Online, Pick Up Instore (BOPIS), curb-side delivery or a local pick up location.</p>
                <p>According to Business Insider, 68% of consumers have made multiple purchases with BOPIS. The benefits of BOPIS include shipping cost savings, speedy delivery and convenience. You can successfully implement BOPIS by integrating eCommerce operations with brick-and-mortar operations to give customers a smooth shopping experience.</p>
                <h2>Real-time Inventory</h2>
                <p>Seamless, integrated supply chain operations require a single view of inventory across multiple sales points. These may include external agencies acting as sales partners. A holistic view provides a clear picture of your most accurate inventory levels at any given moment. This means better customer satisfaction as the correct product availability will display and decrease disappointed customers.</p>
                <p>Real-time inventory visibility lets you strategically move inventory and avoid stock-outs before they happen. This optimizes overall costs and boosts revenue.</p>
                <h2>Lower Costs with Improved Shipping Services</h2>
                <p>As all selling happens via ecommerce channels, retailers must pay higher costs for transportation, warehousing, inventory storage, and packaging and material expenses.</p>
                <p>Try these advanced technologies to lower costs.</p>
                <ul>
                  <li>Automatically assign order fulfillment centers by calculating the delivery location and associated costs</li>
                  <li>Automatic order routing to most efficient supplier</li>
                  <li>Reduce or eliminate late/wrong shipments</li>
                  <li>Automated splitting of orders to multiple fulfillment centers</li>
                  <li>Accurate demand forecast to maintain the right amount of inventory</li>
                </ul>
                <h2>Build Resilient Supply Chains</h2>
                <p>Unexpected challenges always cause problems in the supply chain. We witnessed this during the pandemic. Other unknown challenges can include political events, natural disasters, transportation failures, new competitors, and cyber attacks.</p>
                <p>Adopting the right technology early, recruiting good people and implementing new processes can help a business prepare for the future. Retailers need to build resilience to prepare for new challenges.</p>
                <p>Digital transformation will not happen overnight. All businesses and supply chains are unique. There is no shortcut so finding professional help to navigate your digital transformation is key to reducing the headaches and increasing sales.</p></div>
            </div>
            <div className="w-full lg:w-1/6">
              <div className="w-full lg:w-3/4 text-left lg:text-right flex flex-wrap lg:flex-col justify-between">
                <div className="lg:mb-39">
                  <h5 className="lg:mb-1-1 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                    {post.frontmatter.blog_category}
                  </h5>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3">
                    {post.frontmatter.date}
                  </p>
                </div>
                <div className="flex items-center lg:items-end lg:flex-col ">
                  <Link to={post.frontmatter.twitter} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary "
                      icon={faTwitter}
                    />
                  </Link>
                  <Link to={post.frontmatter.linkedin} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="mx-8 lg:mx-0 lg:my-5-1 font-awesome text-2xl text-term-primary "
                      icon={faLinkedinIn}
                    />
                  </Link>
                  <Link to={post.frontmatter.facebook} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary"
                      icon={faFacebookF}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more articles section */}
      {morepost.length &&
        <section className="pb-50 lg:pb-1-04">
          <MoreArticles articles={morepost} />
        </section>
      }

      {/* stay in loop section */}
      <StayLoop />

    </Layout>

  )
}

export default BlogSingle

export const BlogPageQuery = graphql`
  query BlogPage2($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        blog_category
        templateKey
        facebook
        linkedin
        twitter
        date(formatString: "DD MMM, YYYY")
        
      }
    }
    moreArticles: allMarkdownRemark(
      filter: { 
              id: { ne: $id },
              frontmatter: { templateKey: { eq: "single-blog" } }
            }
      ) {
       edges {
         node {
           id
           fields {
             slug
           }
           frontmatter {
             title
             blog_category
             templateKey
             featured_image {              
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
             date(formatString: "DD MMM, YYYY")
           }
         }
       }
     }
     awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`